import React, { useEffect } from 'react';
import styles from './Footer.module.scss';
import { Link, NavLink } from 'react-router-dom';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape, useIntl } from 'react-intl';
import { routes } from '../../config/Router/routes';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/logo.svg';
import email from '../../assets/icons/email.svg';
import phone from '../../assets/icons/phone.svg';
import mobile from '../../assets/icons/mobile.svg';
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram.svg';
import { NavigationItem } from '../Layout/Layout';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { Locale } from '../../domain/Translation';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import Button from '../Button/Button';
import { useForm } from '../../hooks/useForm/useForm';
import TextField from '../TextField/TextField';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { SubscribeNewsletterRequest } from '../../store/user/service';
import * as userService from '../../store/user/service';
import { resetUserStore } from '../../store/user/actions';

type Props = {
  navigationItems: NavigationItem[];
  selectedLocale: Locale;
  onSubscribe: (inputs: SubscribeNewsletterRequest, intl: IntlShape) => void;
  isSubscribeLoading: boolean;
  isSubscribeSuccess: boolean;
  onResetUserStore: () => void;
};

type FormInputs = {
  email: string;
};

const Footer = ({
  navigationItems,
  selectedLocale,
  isSubscribeLoading,
  onSubscribe,
  isSubscribeSuccess,
  onResetUserStore,
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    return () => onResetUserStore();
  }, []);

  useEffect(() => {
    if (isSubscribeSuccess) {
      onClearInput('email');
      setNewInputObject('email', { validationErrors: [] });

      onResetUserStore();
    }
  }, [isSubscribeSuccess]);

  const getSiteRulesNavigationItems = () => {
    return [
      {
        label: translate(intl, 'FOOTER.LINK_PRIVACY_POLICY'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'FOOTER.MENU_PRIVACY_LINK'),
        ),
      },
      {
        label: translate(intl, 'FOOTER.LINK_PURCHASE_POLICY'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'FOOTER.MENU_PURCHASE_POLICY_LINK'),
        ),
      },
    ];
  };

  const INPUTS = [
    {
      name: 'email',
      placeholder: translate(intl, 'FOOTER.NEWSLETTER_EMAIL'),
      type: 'text',
      validation: [
        {
          type: 'required',
        },
        {
          type: 'email',
        },
        {
          type: 'minLength',
          parameter: 6,
        },
        {
          type: 'maxLength',
          parameter: 100,
        },
      ],
      value: '',
    },
  ];

  const handleSubmit = (submitInputs: FormInputs) => {
    onSubscribe(
      {
        email: submitInputs.email,
      },
      intl,
    );
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onClearInput,
    setNewInputObject,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={styles.footerItemContainer}>
            <div>
              <div className={styles.footerItemTitle}>
                <NavLink to={getLocaleUrl(routes.homepage, selectedLocale)}>
                  <ReactSVG src={logo} />
                </NavLink>
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.COMPANY_DETAILS')}
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.COMPANY_CODE')}
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.VAT_CODE')}
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.BANK_NUMBER')}
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.BANK_INFO')}
              </div>
              <div className={styles.footerItem}>
                {translate(intl, 'FOOTER.SWIFT_CODE')}
              </div>
            </div>
            <div>
              <div className={styles.footerItemTitle}>
                {translate(intl, 'FOOTER.SECTION_MENU')}
              </div>
              {navigationItems.map((item) => (
                <Link
                  key={item.label}
                  to={item.to}
                  className={styles.footerItem}
                >
                  {item.label}
                </Link>
              ))}
            </div>
            <div>
              <div className={styles.footerItemTitle}>
                {translate(intl, 'FOOTER.SECTION_SITE_RULES')}
              </div>
              {getSiteRulesNavigationItems().map((item) => (
                <Link
                  key={item.label}
                  to={item.to}
                  className={styles.footerItem}
                >
                  {item.label}
                </Link>
              ))}
            </div>
            <div>
              <div className={styles.footerItemTitle}>
                {translate(intl, 'FOOTER.SECTION_CONTACT')}
              </div>
              <div className={styles.footerItem}>
                <ReactSVG src={email} />
                <a
                  href={`mailto:${translate(intl, 'FOOTER.SOCIAL_EMAIL')}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {translate(intl, 'FOOTER.SOCIAL_EMAIL')}
                </a>
              </div>
              <div className={styles.footerItem}>
                <ReactSVG src={phone} />
                <a
                  href={`tel:${translate(intl, 'FOOTER.SOCIAL_PHONE')}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {translate(intl, 'FOOTER.SOCIAL_PHONE')}
                </a>
              </div>
              <div className={styles.footerItem}>
                <ReactSVG src={mobile} />
                <a
                  href={`tel:${translate(intl, 'FOOTER.SOCIAL_MOBILE_PHONE')}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {translate(intl, 'FOOTER.SOCIAL_MOBILE_PHONE')}
                </a>
              </div>
              <div className={styles.footerItem}>
                <ReactSVG src={facebook} />
                <a
                  href={translate(intl, 'FOOTER.SOCIAL_FACEBOOK_LINK')}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {translate(intl, 'FOOTER.SOCIAL_FACEBOOK')}
                </a>
              </div>
              <div className={styles.footerItem}>
                <ReactSVG src={instagram} />
                <a
                  href={translate(intl, 'FOOTER.SOCIAL_INSTAGRAM_LINK')}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {translate(intl, 'FOOTER.SOCIAL_INSTAGRAM')}
                </a>
              </div>
            </div>
            <div>
              <div className={styles.footerItemTitle}>
                {translate(intl, 'FOOTER.SECTION_NEWSLETTER')}
              </div>

              <ul className={styles.newsletterContainer}>
                <li>{translate(intl, 'FOOTER.NEWSLETTER_INFO_1')}</li>
                <li>{translate(intl, 'FOOTER.NEWSLETTER_INFO_2')}</li>
                <li>{translate(intl, 'FOOTER.NEWSLETTER_INFO_3')}</li>
              </ul>
              <div className={styles.subscriptionContainer}>
                <div className={styles.inputContainer}>
                  {inputs.map((input) => (
                    <TextField
                      key={input.name}
                      onChange={onInputChange}
                      value={input.value?.toString() ?? ''}
                      placeholder={input.placeholder ?? ''}
                      errors={input.validationErrors ?? []}
                      onBlur={onLoseInputFocus}
                      name={input.name}
                      type={input.type}
                      className={styles.emailInput}
                    />
                  ))}
                </div>
                <Button
                  isLoadingButton
                  className={styles.subscriptionButton}
                  onClick={onSubmit}
                  isLoading={isSubscribeLoading}
                >
                  {translate(intl, 'FOOTER.NEWSLETTER_SUBSCRIBE')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  isSubscribeLoading: state.user.subscribeNewsletterLoading,
  isSubscribeSuccess: state.user.subscribeNewsletterSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSubscribe: (inputs: SubscribeNewsletterRequest, intl: IntlShape) =>
    dispatch(userService.subscribeNewsletter(inputs, intl)),
  onResetUserStore: () => dispatch(resetUserStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
