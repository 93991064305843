import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import cityReducer, { CityStateType } from '../../store/city/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import blogReducer, { BlogStateType } from '../../store/blog/reducer';
import roomReducer, { RoomStateType } from '../../store/room/reducer';
import ageReducer, { AgeStateType } from '../../store/age/reducer';
import periodReducer, { PeriodStateType } from '../../store/period/reducer';
import programReducer, { ProgramStateType } from '../../store/program/reducer';
import programPriceReducer, {
  ProgramPriceStateType,
} from '../../store/programPrice/reducer';
import locationReducer, {
  LocationStateType,
} from '../../store/location/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import nightsPeriodReducer, {
  NightsPeriodStateType,
} from '../../store/nightsPeriod/reducer';
import programDiscountReducer, {
  ProgramDiscountStateType,
} from '../../store/programDiscount/reducer';
import locationContentReducer, {
  LocationContentStateType,
} from '../../store/locationContent/reducer';
import weekdayReducer, { WeekdayStateType } from '../../store/weekday/reducer';
import cityContentReducer, {
  CityContentStateType,
} from '../../store/cityContent/reducer';
import cityServiceReducer, {
  CityServiceStateType,
} from '../../store/cityService/reducer';
import amenityReducer, { AmenityStateType } from '../../store/amenity/reducer';
import transferReducer, {
  TransferStateType,
} from '../../store/transfer/reducer';
import enquiryReducer, { EnquiryStateType } from '../../store/enquiry/reducer';
import basketReducer, { BasketStateType } from '../../store/basket/reducer';
import highlightReducer, {
  HighlightStateType,
} from '../../store/highlight/reducer';
import locationDiscountReducer, {
  LocationDiscountStateType,
} from '../../store/locationDiscount/reducer';
import locationGroupReducer, {
  LocationGroupStateType,
} from '../../store/locationGroup/reducer';
import reviewReducer, { ReviewStateType } from '../../store/review/reducer';
import faqReducer, { FaqStateType } from '../../store/faq/reducer';
import contactReducer, { ContactStateType } from '../../store/contact/reducer';
import specialOfferReducer, {
  SpecialOfferStateType,
} from '../../store/specialOffer/reducer';
import noteReducer, { NoteStateType } from '../../store/note/reducer';

export type StoreState = {
  auth: AuthStateType;
  city: CityStateType;
  location: LocationStateType;
  user: UserStateType;
  blog: BlogStateType;
  faq: FaqStateType;
  room: RoomStateType;
  age: AgeStateType;
  program: ProgramStateType;
  period: PeriodStateType;
  nightsPeriod: NightsPeriodStateType;
  programPrice: ProgramPriceStateType;
  programDiscount: ProgramDiscountStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  locationContent: LocationContentStateType;
  weekday: WeekdayStateType;
  cityContent: CityContentStateType;
  amenity: AmenityStateType;
  transfer: TransferStateType;
  enquiry: EnquiryStateType;
  basket: BasketStateType;
  highlight: HighlightStateType;
  locationDiscount: LocationDiscountStateType;
  locationGroup: LocationGroupStateType;
  review: ReviewStateType;
  cityService: CityServiceStateType;
  contact: ContactStateType;
  specialOffer: SpecialOfferStateType;
  note: NoteStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  city: cityReducer,
  location: locationReducer,
  user: userReducer,
  blog: blogReducer,
  faq: faqReducer,
  room: roomReducer,
  age: ageReducer,
  program: programReducer,
  period: periodReducer,
  programPrice: programPriceReducer,
  translation: translationReducer,
  language: languageReducer,
  nightsPeriod: nightsPeriodReducer,
  programDiscount: programDiscountReducer,
  locationContent: locationContentReducer,
  weekday: weekdayReducer,
  cityContent: cityContentReducer,
  amenity: amenityReducer,
  transfer: transferReducer,
  enquiry: enquiryReducer,
  basket: basketReducer,
  highlight: highlightReducer,
  locationDiscount: locationDiscountReducer,
  locationGroup: locationGroupReducer,
  review: reviewReducer,
  cityService: cityServiceReducer,
  contact: contactReducer,
  specialOffer: specialOfferReducer,
  note: noteReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
